import * as React from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import ConstructionRoundedIcon from '@mui/icons-material/ConstructionRounded';
import SettingsSuggestRoundedIcon from '@mui/icons-material/SettingsSuggestRounded';
import SupportAgentRoundedIcon from '@mui/icons-material/SupportAgentRounded';
import ThumbUpAltRoundedIcon from '@mui/icons-material/ThumbUpAltRounded';
import FastForwardTwoToneIcon from '@mui/icons-material/FastForwardTwoTone';
import {useTranslation} from 'react-i18next';
import {TFunction} from 'i18next';

const items = (t: TFunction<'translation', undefined>) => [
  {
    icon: <SettingsSuggestRoundedIcon />,
    title: t('highlightAdaptable'),
    description: t('highlightAdaptableDescription'),
  },
  {
    icon: <ConstructionRoundedIcon />,
    title: t('highlightHighFrequency'),
    description: t('highlightHighFrequencyDescription'),
  },
  {
    icon: <FastForwardTwoToneIcon />,
    title: t('highlightHighFast'),
    description: t('highlightHighFastDescription'),
  },
  {
    icon: <ThumbUpAltRoundedIcon />,
    title: t('highlightHighUserExperience'),
    description: t('highlightHighUserExperienceDescription'),
  },
  {
    icon: <SupportAgentRoundedIcon />,
    title: t('highlightHighSupport'),
    description: t('highlightHighSupportDescription'),
  },
];

export default function Highlights() {
  const {t} = useTranslation();
  return (
    <Box
      id='highlights'
      sx={{
        pt: {xs: 4, sm: 12},
        pb: {xs: 8, sm: 16},
        color: 'white',
        bgcolor: '#06090a',
      }}
    >
      <Container
        sx={{
          position: 'relative',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          gap: {xs: 3, sm: 6},
        }}
      >
        <Box
          sx={{
            width: {sm: '100%', md: '60%'},
            textAlign: {sm: 'left', md: 'center'},
          }}
        >
          <Typography component='h2' variant='h4'>
            {t('highlights')}
          </Typography>
          <Typography variant='body1' sx={{color: 'grey.400'}}>
            {t('highlightDescription')}
          </Typography>
        </Box>
        <Grid container spacing={2.5}>
          {items(t).map((item, index) => (
            <Grid item xs={12} sm={6} md={4} key={index}>
              <Stack
                direction='column'
                color='inherit'
                component={Card}
                spacing={1}
                useFlexGap
                sx={{
                  p: 3,
                  height: '100%',
                  border: '1px solid',
                  borderColor: 'grey.800',
                  background: 'transparent',
                  backgroundColor: 'grey.900',
                }}
              >
                <Box sx={{opacity: '50%'}}>{item.icon}</Box>
                <div>
                  <Typography fontWeight='medium' gutterBottom>
                    {item.title}
                  </Typography>
                  <Typography variant='body2' sx={{color: 'grey.400'}}>
                    {item.description}
                  </Typography>
                </div>
              </Stack>
            </Grid>
          ))}
        </Grid>
      </Container>
    </Box>
  );
}
