import {Typography} from '@mui/material';
import React from 'react';
import {useTranslation} from 'react-i18next';

export default function Copyright(props: any) {
  const {t} = useTranslation();

  return (
    <>
      <Typography
        variant='body2'
        color='text.secondary'
        align='center'
        {...props}
      >
        {`Copyright © ${t('company')} `}
        {2023}
        {'.'}
      </Typography>
    </>
  );
}
