import axios from 'axios';
import {ZUSER_PLATFORM_ID} from '../constant';

const axiosClient = axios.create({
  baseURL: `${process.env.REACT_APP_SERVER_API}/api/v1/`,
  headers: {'Content-Type': 'application/json; charset=utf-8'},
  timeout: 30000,
  // withCredentials: true,
  validateStatus: function (status) {
    return status >= 200 && status < 500; // default
  },
});

axiosClient.interceptors.request.use(
  function (config) {
    const token = localStorage.getItem('token');
    if (token && !config.headers.hasOwnProperty('Authorization')) {
      config.headers['Authorization'] = 'Bearer ' + token;
    }
    let platformId = localStorage.getItem('platformId');
    if (!platformId) {
      platformId = ZUSER_PLATFORM_ID;
    }
    config.headers['platformid'] = platformId;

    return config;
  },
  function (error) {
    return Promise.reject(error);
  },
);

axiosClient.interceptors.response.use(
  function (response) {
    // response.headers['Access-Control-Allow-Origin'] = 'http://localhost:7032';
    // // response.headers['Access-Control-Allow-Origin'] = 'http://localhost:3001';
    // response.headers['Access-Control-Allow-Credentials'] = 'true';
    // response.headers['Access-Control-Allow-Methods'] =
    //   'GET,HEAD,OPTIONS,POST,PUT';
    // response.headers['Access-Control-Allow-Headers'] =
    //   'Access-Control-Allow-Headers, Origin,Accept, X-Requested-With, Content-Type, Access-Control-Request-Method, Access-Control-Request-Headers, platformid';

    return response;
  },
  function (error) {
    console.log('error', error);
    handleErrorStatus(error.response?.status);
    // return Promise.reject(error);
    return error;
  },
);

function handleErrorStatus(status: number) {
  // switch (status) {
  //   // forbidden resource
  //   case 403:
  //   // merchant not found
  //   case 404:
  //     router.push('/admin/merchants');
  //     break;
  //   default:
  //     router.push('/');
  //     break;
  // }
}

export default function (method: string, url: string, data = {}, config = {}) {
  method = method.toLowerCase();
  switch (method) {
    case 'post':
      return axiosClient[method](url, data, config);
    case 'get':
      return axiosClient[method](url, {params: data, ...config});
    case 'delete':
      return axiosClient[method](url, {data});
    case 'put':
      return axiosClient[method](url, data, config);
    case 'patch':
      return axiosClient[method](url, data, config);
  }
}
