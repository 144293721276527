import {GetRoleListDto, PatchRoleDto, PostRoleDto} from './dto/role';
import callApi from './index';

export const createRole = (data: PostRoleDto) => {
  return callApi('post', `/role`, data);
};

export const getRoleList = (data: GetRoleListDto) => {
  return callApi('get', `/role/list`, data);
};

export const updateRole = (id: string, data: PatchRoleDto) => {
  return callApi('patch', `/role/${id}`, data);
};

export const deleteRole = (id: string) => {
  return callApi('delete', `/role/${id}`);
};
