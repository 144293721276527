import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import IconButton from '@mui/material/IconButton';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import GithubIcon from '@mui/icons-material/GitHub';
import {useTranslation} from 'react-i18next';
import Copyright from './Copyright';

export default function Footer() {
  const {t} = useTranslation();
  return (
    <Container
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: {xs: 4, sm: 8},
        py: {xs: 8, sm: 10},
        textAlign: {sm: 'center', md: 'left'},
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: {xs: 'column', sm: 'row'},
          width: '100%',
          justifyContent: 'space-between',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 4,
            minWidth: {xs: '100%', sm: '60%'},
          }}
        >
          <Box sx={{width: {xs: '100%', sm: '60%'}}}>
            <Box sx={{ml: '-15px'}}>
              <Link href='/'>
                <img
                  src='/logo-symbol.png'
                  alt='brand-logo'
                  width={45}
                  height={35}
                />
                <span className='brand-logo-text'>{t('company')}</span>
              </Link>
            </Box>
            <br />
            <Link color='text.secondary' href='/policy'>
              {t('privacyPolicy')}
            </Link>
            <Typography display='inline' sx={{mx: 0.5, opacity: 0.5}}>
              &nbsp;•&nbsp;
            </Typography>
            <Link color='text.secondary' href='/policy'>
              {t('termsOfService')}
            </Link>
          </Box>
        </Box>
        <Box
          sx={{
            display: {xs: 'none', sm: 'flex'},
            flexDirection: 'column',
            gap: 1,
          }}
        >
          <Typography variant='body2' fontWeight={600}>
            {t('footerProduct')}
          </Typography>
          <Link color='text.secondary' href='#'>
            {t('features')}
          </Link>
          <Link color='text.secondary' href='#'>
            {t('testimonials')}
          </Link>
          <Link color='text.secondary' href='#'>
            {t('highlights')}
          </Link>
          <Link color='text.secondary' href='#'>
            {t('pricing')}
          </Link>
          <Link color='text.secondary' href='#'>
            {t('faq')}
          </Link>
        </Box>
        <Box
          sx={{
            display: {xs: 'none', sm: 'flex'},
            flexDirection: 'column',
            gap: 1,
          }}
        >
          <Typography variant='body2' fontWeight={600}>
            {t('footerCompany')}
          </Typography>
          <Link color='text.secondary' href='#'>
            {t('aboutUs')}
          </Link>
        </Box>
        <Box
          sx={{
            display: {xs: 'none', sm: 'flex'},
            flexDirection: 'column',
            gap: 1,
          }}
        >
          <Typography variant='body2' fontWeight={600}>
            {t('footerLegal')}
          </Typography>
          <Link color='text.secondary' href='/policy'>
            {t('terms')}
          </Link>
          <Link color='text.secondary' href='/policy'>
            {t('privacy')}
          </Link>
          <Link color='text.secondary' href='mailto:support@zzztech.com.tw'>
            {t('contact')}
          </Link>
        </Box>
      </Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          pt: {xs: 4, sm: 8},
          width: '100%',
          borderTop: '1px solid',
          borderColor: 'divider',
        }}
      >
        <div>
          <Copyright />
        </div>
        <Stack
          direction='row'
          justifyContent='left'
          spacing={1}
          useFlexGap
          sx={{
            color: 'text.secondary',
          }}
        >
          <IconButton
            color='inherit'
            href='https://github.com/ZzzTechCorp'
            aria-label='GitHub'
            sx={{alignSelf: 'center'}}
          >
            <GithubIcon />
          </IconButton>
        </Stack>
      </Box>
    </Container>
  );
}
