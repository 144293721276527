import {Box} from '@mui/material';
import {useTranslation} from 'react-i18next';
import {Link} from 'react-router-dom';
import AppAppBar from '../components/AppAppBar';
import {TFunction} from 'i18next';
import React from 'react';

const logoStyle = {
  width: '200px',
  height: 'auto',
  cursor: 'pointer',
};

export default function NotFound() {
  const {t, i18n} = useTranslation();
  const localeI18n = localStorage.getItem('i18n');
  React.useEffect(() => {
    i18n.changeLanguage(localeI18n as string);
  }, []);
  return (
    <div>
      {/* <AppAppBar mode={'light'} toggleColorMode={() => {}}></AppAppBar> */}
      <Box
        display='grid'
        gridTemplateRows='repeat(3, 3fr)'
        justifyContent='center'
        justifyItems='center'
        alignItems='center'
        // gridAutoRows='10/px'
        minHeight='100vh'
      >
        <Link className='mx-20' to='/'>
          <img src={'/error-404.png'} style={logoStyle} alt='logo of zzztech' />
        </Link>
        <h1>{t('notFound')}</h1>
      </Box>
    </div>
  );
}
