import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import Chip from '@mui/material/Chip';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Container from '@mui/material/Container';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import {useTranslation} from 'react-i18next';
import {TFunction} from 'i18next';

const tiers = (t: TFunction<'translation', undefined>) => [
  {
    title: t('pricingFree'),
    price: '0',
    description: [
      `1 ${t('dashboardMenuPlatform')}`,
      `50 ${t('dashboardMenuUser')} ${t('pricingIncludes')}`,
      `5 ${t('dashboardMenuRole')} ${t('pricingIncludes')}`,
      `10 ${t('dashboardMenuPermission')} ${t('pricingIncludes')}`,
      `${t('pricingHelpCenter')}`,
      `${t('pricingEmail')}`,
    ],
    buttonText: t('signupForFree'),
    buttonVariant: 'outlined',
    href: '/sign-up',
  },
  {
    title: t('pricingProfessional'),
    subheader: 'Recommended',
    price: '15',
    description: [
      `3 ${t('dashboardMenuPlatform')}`,
      `5000 ${t('dashboardMenuUser')} ${t('pricingIncludes')}`,
      `20 ${t('dashboardMenuRole')} ${t('pricingIncludes')}`,
      `50 ${t('dashboardMenuPermission')} ${t('pricingIncludes')}`,
      `${t('pricingHelpCenter')}`,
      `${t('pricingPriorityEmail')}`,
      `${t('pricingDedicatedTeam')}`,
      `${t('pricingBestDeals')}`,
    ],
    buttonText: t('contact'),
    buttonVariant: 'contained',
    href: 'mailto:support@zzztech.com.tw',
  },
  {
    title: t('pricingEnterprise'),
    price: '998',
    description: [
      `10 ${t('dashboardMenuPlatform')}`,
      `100000 ${t('dashboardMenuUser')} ${t('pricingIncludes')}`,
      `200 ${t('dashboardMenuRole')} ${t('pricingIncludes')}`,
      `1000 ${t('dashboardMenuPermission')} ${t('pricingIncludes')}`,
      `${t('pricingHelpCenter')}`,
      `${t('pricingPhoneAndEmailSupport')}`,
    ],
    buttonText: t('contact'),
    buttonVariant: 'outlined',
    href: 'mailto:support@zzztech.com.tw',
  },
];

export default function Pricing() {
  const {t} = useTranslation();
  return (
    <Container
      id='pricing'
      sx={{
        pt: {xs: 4, sm: 12},
        pb: {xs: 8, sm: 16},
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: {xs: 3, sm: 6},
      }}
    >
      <Box
        sx={{
          width: {sm: '100%', md: '60%'},
          textAlign: {sm: 'left', md: 'center'},
        }}
      >
        <Typography component='h2' variant='h4' color='text.primary'>
          {t('pricing')}
        </Typography>
        <Typography variant='body1' color='text.secondary'>
          {t('pricingDescription')}
        </Typography>
      </Box>
      <Grid container spacing={3} alignItems='center' justifyContent='center'>
        {tiers(t).map(tier => (
          <Grid
            item
            key={tier.title}
            xs={12}
            sm={tier.title === t('pricingEnterprise') ? 12 : 6}
            md={4}
          >
            <Card
              sx={{
                p: 2,
                display: 'flex',
                flexDirection: 'column',
                gap: 4,
                border:
                  tier.title === t('pricingProfessional')
                    ? '1px solid'
                    : undefined,
                borderColor:
                  tier.title === t('pricingProfessional')
                    ? 'primary.main'
                    : undefined,
                background:
                  tier.title === t('pricingProfessional')
                    ? 'linear-gradient(#033363, #021F3B)'
                    : undefined,
              }}
            >
              <CardContent>
                <Box
                  sx={{
                    mb: 1,
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    color:
                      tier.title === t('pricingProfessional') ? 'grey.100' : '',
                  }}
                >
                  <Typography component='h3' variant='h6'>
                    {tier.title}
                  </Typography>
                  {tier.title === t('pricingProfessional') && (
                    <Chip
                      icon={<AutoAwesomeIcon />}
                      label={tier.subheader}
                      size='small'
                      sx={{
                        background: theme =>
                          theme.palette.mode === 'light' ? '' : 'none',
                        backgroundColor: 'primary.contrastText',
                        '& .MuiChip-label': {
                          color: 'primary.dark',
                        },
                        '& .MuiChip-icon': {
                          color: 'primary.dark',
                        },
                      }}
                    />
                  )}
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'baseline',
                    color:
                      tier.title === t('pricingProfessional')
                        ? 'grey.50'
                        : undefined,
                  }}
                >
                  <Typography component='h3' variant='h2'>
                    ${tier.price}
                  </Typography>
                  <Typography component='h3' variant='h6'>
                    &nbsp; {t('pricingPerMonth')}
                  </Typography>
                </Box>
                <Divider
                  sx={{
                    my: 2,
                    opacity: 0.2,
                    borderColor: 'grey.500',
                  }}
                />
                {tier.description.map(line => (
                  <Box
                    key={line}
                    sx={{
                      py: 1,
                      display: 'flex',
                      gap: 1.5,
                      alignItems: 'center',
                    }}
                  >
                    <CheckCircleRoundedIcon
                      sx={{
                        width: 20,
                        color:
                          tier.title === t('pricingProfessional')
                            ? 'primary.light'
                            : 'primary.main',
                      }}
                    />
                    <Typography
                      component='text'
                      variant='subtitle2'
                      sx={{
                        color:
                          tier.title === t('pricingProfessional')
                            ? 'grey.200'
                            : undefined,
                      }}
                    >
                      {line}
                    </Typography>
                  </Box>
                ))}
              </CardContent>
              <CardActions>
                <Button
                  fullWidth
                  variant={tier.buttonVariant as 'outlined' | 'contained'}
                  component='a'
                  href={tier.href}
                  target='_blank'
                >
                  {tier.buttonText}
                </Button>
              </CardActions>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
}
