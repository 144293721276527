import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import {createTheme, ThemeProvider} from '@mui/material/styles';
import Copyright from '../components/Copyright';
import KeyboardReturnIcon from '@mui/icons-material/KeyboardReturn';
import {useTranslation} from 'react-i18next';
import {postSignUp} from '../api/auth';
import {
  Alert,
  AlertColor,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Snackbar,
  SnackbarOrigin,
} from '@mui/material';
import {useNavigate} from 'react-router-dom';
import {HttpStatusCode} from 'axios';
import {ALERT_HIDE_DURATION} from '../constant';
import {getPlatformPublicList} from '../api/platform';

// TODO remove, this demo shouldn't need to reset the theme.
const defaultTheme = createTheme();
interface State extends SnackbarOrigin {
  open: boolean;
  type: AlertColor;
  message: string;
}

export default function SignUp() {
  const {t, i18n} = useTranslation();
  const localeI18n = localStorage.getItem('i18n');
  const [email, setEmail] = React.useState('');
  const [password, setPassword] = React.useState('');
  const [confirmPassword, setConfirmPassword] = React.useState('');
  const [username, setUsername] = React.useState('');
  const [platformId, setPlatformId] = React.useState('');
  const [platformList, setPlatformList] = React.useState<
    {
      name: string;
      id: string;
      returnUrl?: string;
      cancelUrl?: string;
      policyUrl?: string;
    }[]
  >([]);
  const [cancelUrl, setCancelUrl] = React.useState('/');
  const [returnUrl, setReturnUrl] = React.useState('/dashboard');
  const [policyUrl, setPolicyUrl] = React.useState('/policy');
  const [searchPlatformId, setSearchPlatformId] = React.useState<string>('');
  const [disabledPlatformId, setDisabledPlatformId] =
    React.useState<boolean>(false);

  const [state, setState] = React.useState<State>({
    open: false,
    type: 'success',
    message: '',
    vertical: 'bottom',
    horizontal: 'right',
  });
  const {open} = state;

  const handleClick = (type: AlertColor, message: string) => {
    setState({...state, type, message, open: true});
  };

  const handleClose = () => {
    setState({...state, open: false});
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (password !== confirmPassword) {
      return handleClick('error', t('signupPasswordValidate'));
    }

    const signup = await postSignUp({
      username,
      email,
      password,
      platformId,
    });

    if (signup?.status === HttpStatusCode.Ok) {
      localStorage.setItem('token', signup.data.data.token);
      localStorage.setItem('platformId', platformId);
      handleClick('success', t('signupSuccess'));
      let resultUrl: string | URL = returnUrl;
      try {
        const url = new URL(returnUrl);
        const urlParams = new URLSearchParams(url.search);
        urlParams.set('token', signup.data.data.token);
        urlParams.set('event', 'signup');
        url.search = urlParams.toString();
        resultUrl = url;
      } catch (e) {}

      window.location.replace(resultUrl);
    } else {
      handleClick('error', signup?.data.message);
    }
  };

  React.useEffect(() => {
    i18n.changeLanguage(localeI18n as string);
  }, []);

  React.useEffect(() => {
    const urlSearch = new URLSearchParams(window.location.search);

    const platformId = urlSearch.get('platformId');
    if (platformId) {
      platformList.map(platform => {
        if (platform.id === platformId) {
          setPlatformId(platform.id);
          localStorage.setItem('platformId', platformId);
          platform.returnUrl && setReturnUrl(platform.returnUrl);
          platform.cancelUrl && setCancelUrl(platform.cancelUrl);
          platform.policyUrl && setPolicyUrl(platform.policyUrl);
        }
      });
      setSearchPlatformId(platformId);
      setDisabledPlatformId(true);
    }
  }, [platformList]);

  React.useEffect(() => {
    getPlatformPublicList({limit: 1000})
      ?.then(d => {
        setPlatformList(d.data.items.map((item: any) => item));
      })
      .catch(e => {
        handleClick('error', e);
      });
  }, []);

  return (
    <ThemeProvider theme={defaultTheme}>
      <Button
        color='primary'
        variant='contained'
        size='small'
        component='a'
        rel='noopener'
        onClick={() => window.location.replace(cancelUrl)}
      >
        <KeyboardReturnIcon />
      </Button>
      <Container component='main' maxWidth='xs'>
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Avatar sx={{m: 1, bgcolor: 'primary.dark'}}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component='h1' variant='h5'>
            {t('signup')}
          </Typography>
          <FormControl sx={{m: 1, minWidth: 120}} disabled></FormControl>
          <Box component='form' onSubmit={handleSubmit} sx={{mt: 1}}>
            <TextField
              margin='normal'
              required
              fullWidth
              id='email'
              label={t('signinEmail')}
              name='email'
              autoComplete='email'
              autoFocus
              value={email}
              onChange={({target: {value}}) => {
                setEmail(value);
              }}
            />
            <TextField
              margin='normal'
              required
              fullWidth
              name='username'
              label={t('signupUsername')}
              type='string'
              id='username'
              autoComplete='username'
              value={username}
              onChange={({target: {value}}) => {
                setUsername(value);
              }}
            />
            <TextField
              margin='normal'
              required
              fullWidth
              name='password'
              label={t('signinPassword')}
              type='password'
              id='password'
              autoComplete='current-password'
              value={password}
              onChange={({target: {value}}) => {
                setPassword(value);
              }}
            />
            <TextField
              margin='normal'
              required
              fullWidth
              name='confirmPassword'
              label={t('signupConfirmPassword')}
              type='password'
              id='confirmPassword'
              autoComplete='confirm-password'
              value={confirmPassword}
              onChange={({target: {value}}) => {
                setConfirmPassword(value);
              }}
            />

            <FormControl required fullWidth disabled={disabledPlatformId}>
              <InputLabel id='platform-simple-select-label'>
                {t('signinPlatformName')}
              </InputLabel>
              <Select
                labelId='platform-simple-select-label'
                id='platform-simple-select'
                value={searchPlatformId || platformId}
                label={t('signinPlatformName')}
                onChange={v => {
                  setPlatformId(v.target.value);
                }}
              >
                {platformList.map(g => {
                  return <MenuItem value={g.id}>{g.name}</MenuItem>;
                })}
              </Select>
            </FormControl>

            <Button
              type='submit'
              fullWidth
              variant='contained'
              sx={{mt: 3, mb: 2}}
            >
              {t('signup')}
            </Button>

            <Grid container>
              <Grid item>
                <Link href={policyUrl} variant='body2'>
                  {t('signupPolicy')}
                </Link>
              </Grid>
            </Grid>
          </Box>
        </Box>
        <Copyright sx={{mt: 8, mb: 4}} />
      </Container>
      <Snackbar
        anchorOrigin={{vertical: state.vertical, horizontal: state.horizontal}}
        open={open}
        autoHideDuration={ALERT_HIDE_DURATION}
        onClose={handleClose}
      >
        <Alert
          onClose={handleClose}
          severity={state.type}
          variant='filled'
          sx={{width: '100%'}}
        >
          {state.message}
        </Alert>
      </Snackbar>
    </ThemeProvider>
  );
}
