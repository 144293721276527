import {
  GetPlatformListDto,
  PatchPlatformDto,
  PostPlatformDto,
} from './dto/platform';
import callApi from './index';

export const createPlatform = (data: PostPlatformDto) => {
  data.isActive = Boolean(data.isActive);
  return callApi('post', `/platform`, data);
};

export const getPlatformList = (data: GetPlatformListDto) => {
  return callApi('get', `/platform/list`, data);
};

export const getPlatformPublicList = (data: GetPlatformListDto) => {
  return callApi('get', `/platform/public/list`, data);
};

export const updatePlatform = (id: string, data: PatchPlatformDto) => {
  if (data.isActive !== undefined) {
    data.isActive = Boolean(data.isActive);
  }

  return callApi('patch', `/platform/${id}`, data);
};

export const deletePlatform = (id: string) => {
  return callApi('delete', `/platform/${id}`);
};
