import {GetUserListDto, PatchUserDto, PostUserDto} from './dto/user';
import callApi from './index';

export const getUserMe = () => {
  return callApi('get', `/user/me`);
};

export const getUserMeAccount = () => {
  return callApi('get', `/user/me/account`);
};

export const createUser = (data: PostUserDto) => {
  return callApi('post', `/user`, data);
};

export const getUserList = (data: GetUserListDto) => {
  return callApi('get', `/user/list`, data);
};

export const updateUser = (id: string, data: PatchUserDto) => {
  return callApi('patch', `/user/${id}`, data);
};

export const deleteUser = (id: string) => {
  return callApi('delete', `/user/${id}`);
};
