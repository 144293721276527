import * as React from 'react';
import {alpha} from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import {useTranslation} from 'react-i18next';
import {useNavigate} from 'react-router-dom';

export default function Hero() {
  const {t} = useTranslation();
  const navigate = useNavigate();

  const handleStart = () => {
    navigate('/dashboard');
  };

  return (
    <Box
      id='hero'
      sx={theme => ({
        width: '100%',
        backgroundImage:
          theme.palette.mode === 'light'
            ? 'linear-gradient(180deg, #CEE5FD, #FFF)'
            : `linear-gradient(#02294F, ${alpha('#090E10', 0.0)})`,
        backgroundSize: '100% 20%',
        backgroundRepeat: 'no-repeat',
      })}
    >
      <Container
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          pt: {xs: 14, sm: 20},
          pb: {xs: 8, sm: 12},
        }}
      >
        <Stack spacing={2} useFlexGap sx={{width: {xs: '100%', sm: '70%'}}}>
          <Typography
            variant='h1'
            sx={{
              display: 'flex',
              flexDirection: {xs: 'column', md: 'row'},
              alignSelf: 'center',
              textAlign: 'center',
              fontSize: 'clamp(3.5rem, 10vw, 4rem)',
            }}
          >
            {t('homePageTitle')}&nbsp;
            <Typography
              component='span'
              variant='h1'
              sx={{
                fontSize: 'clamp(3rem, 10vw, 4rem)',
                color: theme =>
                  theme.palette.mode === 'light'
                    ? 'primary.main'
                    : 'primary.light',
              }}
            >
              {t('homePageTitleProduct')}
            </Typography>
          </Typography>
          <Typography
            textAlign='center'
            color='text.secondary'
            sx={{alignSelf: 'center', width: {sm: '100%', md: '80%'}}}
          >
            {t('homePageTitleDescrption')}
          </Typography>
          <Stack
            direction={{xs: 'column', sm: 'row'}}
            alignSelf='center'
            spacing={1}
            useFlexGap
            sx={{pt: 2, width: {xs: '100%', sm: 'auto'}}}
          >
            <Button variant='contained' color='primary' onClick={handleStart}>
              {t('homePageTitleStartNowBtn')}
            </Button>
          </Stack>
          <Typography variant='caption' textAlign='center' sx={{opacity: 0.8}}>
            {t('homePageTitleNotice')}
            <Link href='#' color='primary'>
              {t('homePageTitleTermAndCondition')}
            </Link>
            .
          </Typography>
        </Stack>

        <Box
          id='image'
          sx={theme => ({
            mt: {xs: 1, sm: 1},
            alignSelf: 'center',
            height: {xs: 400, sm: 1100},
            width: '100%',
            backgroundImage:
              theme.palette.mode === 'light'
                ? 'url("/homepage_banner.jpg")'
                : 'url("/homepage_banner.jpg")',
            backgroundSize: 'cover',
            borderRadius: '10px',
            outline: '1px solid',
            outlineColor:
              theme.palette.mode === 'light'
                ? alpha('#BFCCD9', 0.5)
                : alpha('#9CCCFC', 0.1),
            boxShadow:
              theme.palette.mode === 'light'
                ? `0 0 12px 8px ${alpha('#9CCCFC', 0.2)}`
                : `0 0 24px 12px ${alpha('#033363', 0.2)}`,
          })}
        />
      </Container>
    </Box>
  );
}
