import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import {BrowserRouter, Routes, Route} from 'react-router-dom';
import reportWebVitals from './reportWebVitals';
import SignIn from './page/SignIn';
import NotFound from './page/NotFound';
import './i18n';
import SignUp from './page/Signup';
import ProductPolicy from './page/ProductPolicy';
import DashboardUser from './page/dashboard/User';
import DashboardPlatform from './page/dashboard/Platform';
import DashboardRole from './page/dashboard/Role';
import DashboardPermission from './page/dashboard/Permission';
import DashboardIndex from './page/dashboard/AccountDashboard';
import DashboardAccount from './page/dashboard/Account';
import ChoosePlatform from './page/dashboard/ChoosePlatform';

if (!localStorage.getItem('i18n')) {
  localStorage.setItem('i18n', 'zh-TW');
}

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);

root.render(
  <BrowserRouter>
    <Routes>
      <Route path='/' element={<App />}></Route>
      <Route path='/sign-in' element={<SignIn />} />
      <Route path='/sign-up' element={<SignUp />} />
      <Route path='/policy' element={<ProductPolicy />} />
      <Route path='/dashboard' element={<DashboardIndex />} />
      <Route path='/dashboard/platforms' element={<DashboardPlatform />} />
      <Route path='/dashboard/users' element={<DashboardUser />} />
      <Route path='/dashboard/roles' element={<DashboardRole />} />
      <Route path='/dashboard/permissions' element={<DashboardPermission />} />
      <Route path='/dashboard/account' element={<DashboardAccount />} />
      <Route path='/dashboard/choosePlatform' element={<ChoosePlatform />} />
      <Route path='*' element={<NotFound />} />
    </Routes>
  </BrowserRouter>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
