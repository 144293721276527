import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import {useTranslation} from 'react-i18next';
import Dashboard from '../../components/Dashboard';
import React, {ReactElement, useMemo, useState} from 'react';
import TWithProviders, {
  MRT_ColumnDef_Add_Show,
  validateEmail,
  validateLengthMoreThanEqual,
  validateLoginType,
  validateRequired,
} from '../../components/MaterialTable';
import {createUser, deleteUser, getUserList, updateUser} from '../../api/user';
import {MRT_ColumnDef} from 'material-react-table';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import {Alert, AlertColor, Chip, Snackbar, SnackbarOrigin} from '@mui/material';
import {ALERT_HIDE_DURATION} from '../../constant';
dayjs.extend(utc);

function validate(data: any) {
  return {
    username: !validateRequired(data.username) ? 'User Name is Required' : '',
    email: !validateEmail(data.email) ? 'Incorrect Email Format' : '',
    password: !validateLengthMoreThanEqual(data.password, 6)
      ? 'Password must be more than 6'
      : '',
    loginType: !validateLoginType(data.loginType)
      ? 'LoginType Must be `email` or `google`'
      : '',
  };
}

interface State extends SnackbarOrigin {
  open: boolean;
  type: AlertColor;
  message: string;
}
function UserContent(): ReactElement {
  const [validationErrors, setValidationErrors] = useState<
    Record<string, string | undefined>
  >({});
  const {t} = useTranslation();

  const [state, setState] = React.useState<State>({
    open: false,
    type: 'success',
    message: '',
    vertical: 'bottom',
    horizontal: 'right',
  });
  const {open} = state;

  const handleClick = (type: AlertColor, message: string) => {
    setState({...state, type, message, open: true});
  };

  const handleClose = () => {
    setState({...state, open: false});
  };

  const columns = useMemo<MRT_ColumnDef_Add_Show<any>[]>(
    () => [
      {
        accessorKey: 'id',
        header: 'Id',
        enableEditing: false,
        size: 80,
      },
      {
        accessorKey: 'username',
        header: 'Username',
        muiEditTextFieldProps: {
          required: true,
          error: !!validationErrors?.username,
          helperText: validationErrors?.username,
          onFocus: () =>
            setValidationErrors({
              ...validationErrors,
              username: undefined,
            }),
        },
      },
      {
        accessorKey: 'email',
        header: 'Email',
        muiEditTextFieldProps: {
          type: 'email',
          required: true,
          error: !!validationErrors?.email,
          helperText: validationErrors?.email,
          onFocus: () =>
            setValidationErrors({
              ...validationErrors,
              email: undefined,
            }),
        },
      },
      {
        accessorKey: 'password',
        header: 'Password',
        enableSorting: false,
        Cell: () => (
          <Box sx={{display: 'flex', gap: '2ch', alignItems: 'center'}}>
            ******
          </Box>
        ),
        muiEditTextFieldProps: {
          type: 'password',
          required: true,
          error: !!validationErrors?.password,
          helperText: validationErrors?.password,
        },
      },
      {
        accessorKey: 'loginType',
        header: 'LoginType',
        muiEditTextFieldProps: {
          type: 'loginType',
          required: false,
          error: !!validationErrors?.loginType,
          helperText: validationErrors?.loginType,
          onFocus: () =>
            setValidationErrors({
              ...validationErrors,
              loginType: undefined,
            }),
        },
      },
      {
        accessorKey: 'createdAt',
        header: 'createdAt',
        enableEditing: false,
        Cell: ({cell}) => (
          <Box
            component='span'
            sx={theme => ({
              backgroundColor:
                cell.getValue<number>() < 50_000
                  ? theme.palette.error.main
                  : cell.getValue<number>() >= 50_000 &&
                    cell.getValue<number>() < 75_000
                  ? theme.palette.warning.main
                  : theme.palette.success.main,
              borderRadius: '0.25rem',
              color: '#fff',
              maxWidth: '9ch',
              p: '0.25rem',
            })}
          >
            {dayjs
              .utc(cell.getValue<number>())
              .format('YYYY/MM/DD HH:mm:ssZ[Z]')}
          </Box>
        ),
      },
      {
        accessorKey: 'userRolePlatforms',
        header: 'role',
        enableEditing: true,
        Cell: ({cell}) => (
          <>
            {cell
              .getValue<any>()
              ?.map((d: {role: {name: string}; platform: {name: string}}) => (
                <Box sx={{display: 'flex', gap: '2ch', alignItems: 'center'}}>
                  <Chip label={`${d.platform.name}:${d.role.name}`} />
                </Box>
              ))}
          </>
        ),
      },
    ],
    [validationErrors],
  );

  return (
    <Container
      id='pricing'
      sx={{
        pt: {xs: 4, sm: 12},
        pb: {xs: 8, sm: 16},
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: {xs: 3, sm: 6},
      }}
    >
      <Box
        sx={{
          width: {sm: '100%', md: '60%'},
          textAlign: {sm: 'left', md: 'center'},
        }}
      >
        <Typography component='h2' variant='h4' color='text.primary'>
          {t('dashboardMenuUser')}
        </Typography>
        <Typography variant='body1' color='text.secondary'>
          {t('dashboardTableUserDescription')}
        </Typography>
      </Box>
      <Grid
        container
        spacing={3}
        alignItems='center'
        justifyContent='center'
      ></Grid>
      <TWithProviders
        props={{
          createApi: createUser,
          updateApi: updateUser,
          getListApi: getUserList,
          deleteApi: deleteUser,
        }}
        columns={columns}
        validate={validate}
        setValidateErrors={setValidationErrors}
        handleClick={handleClick}
      ></TWithProviders>
      <Snackbar
        anchorOrigin={{vertical: state.vertical, horizontal: state.horizontal}}
        open={open}
        autoHideDuration={ALERT_HIDE_DURATION}
        onClose={handleClose}
      >
        <Alert
          onClose={handleClose}
          severity={state.type}
          variant='filled'
          sx={{width: '100%'}}
        >
          {state.message}
        </Alert>
      </Snackbar>
    </Container>
  );
}

export default function DashboardUser() {
  const {t} = useTranslation();
  return <Dashboard element={UserContent()}></Dashboard>;
}
