import {PostGoogleSignInDto, PostSignInDto, PostSignUpDto} from './dto/auth';
import callApi from './index';

export const postSignIn = (data: PostSignInDto) => {
  return callApi('post', `/auth/signin`, data);
};

export const postGoogleSignIn = (data: PostGoogleSignInDto) => {
  return callApi('post', `/auth/google/signin`, data);
};

export const postSignUp = (data: PostSignUpDto) => {
  return callApi('post', `/auth/signup`, data);
};
