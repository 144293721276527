import {
  GetPermissionListDto,
  PatchPermissionDto,
  PostPermissionDto,
} from './dto/permission';
import callApi from './index';

export const createPermission = (data: PostPermissionDto) => {
  return callApi('post', `/permission`, data);
};

export const getPermissionList = (data: GetPermissionListDto) => {
  return callApi('get', `/permission/list`, data);
};

export const updatePermission = (id: string, data: PatchPermissionDto) => {
  return callApi('patch', `/permission/${id}`, data);
};

export const deletePermission = (id: string) => {
  return callApi('delete', `/permission/${id}`);
};
