import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import {createTheme, ThemeProvider} from '@mui/material/styles';
import Copyright from '../components/Copyright';
import KeyboardReturnIcon from '@mui/icons-material/KeyboardReturn';
import {useTranslation} from 'react-i18next';
import {postSignIn} from '../api/auth';
import LoginIcon from '@mui/icons-material/Login';
import {
  Alert,
  AlertColor,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Snackbar,
  SnackbarOrigin,
} from '@mui/material';
import {HttpStatusCode} from 'axios';
import {ALERT_HIDE_DURATION} from '../constant';
import {getPlatformPublicList} from '../api/platform';
import {GoogleLoginButton} from '../components/GoogleLogin';

const defaultTheme = createTheme();
interface State extends SnackbarOrigin {
  open: boolean;
  type: AlertColor;
  message: string;
}

export default function SignIn() {
  const {t, i18n} = useTranslation();
  const localeI18n = localStorage.getItem('i18n');
  const [email, setEmail] = React.useState('');
  const [cancelUrl, setCancelUrl] = React.useState('/');
  const [returnUrl, setReturnUrl] = React.useState('/dashboard');
  const [policyUrl, setPolicyUrl] = React.useState('/policy');
  const [searchPlatformId, setSearchPlatformId] = React.useState<string>('');
  const [disabledPlatformId, setDisabledPlatformId] =
    React.useState<boolean>(false);
  const [password, setPassword] = React.useState('');
  const [platformId, setPlatformId] = React.useState('');
  const [platformList, setPlatformList] = React.useState<
    {
      name: string;
      id: string;
      isActive: boolean;
      returnUrl?: string;
      cancelUrl?: string;
      policyUrl?: string;
    }[]
  >([]);

  const [state, setState] = React.useState<State>({
    open: false,
    type: 'success',
    message: '',
    vertical: 'bottom',
    horizontal: 'right',
  });
  const {open} = state;

  const handleClick = (type: AlertColor, message: string) => {
    setState({...state, type, message, open: true});
  };

  const handleClose = () => {
    setState({...state, open: false});
  };

  const successFunc = (signinData: {data: any}) => {
    localStorage.setItem('token', signinData.data.data.token);
    localStorage.setItem('platformId', platformId);
    handleClick('success', t('signinSuccess'));

    let resultUrl: string | URL = returnUrl;
    try {
      const url = new URL(returnUrl);
      const urlParams = new URLSearchParams(url.search);
      urlParams.set('token', signinData.data.data.token);
      urlParams.set('event', 'signin');
      url.search = urlParams.toString();
      resultUrl = url;
    } catch (e) {}

    window.location.replace(resultUrl);
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const signin = await postSignIn({
      email,
      password,
      platformId,
    });

    if (signin?.status === HttpStatusCode.Ok) {
      successFunc(signin);
    } else {
      handleClick('error', signin?.data.message);
    }
  };

  React.useEffect(() => {
    i18n.changeLanguage(localeI18n as string);
  }, []);

  React.useEffect(() => {
    getPlatformPublicList({limit: 1000})
      ?.then(d => {
        setPlatformList(d.data.items.map((item: any) => item));
      })
      .catch(e => {
        handleClick('error', e);
      });
  }, []);

  React.useEffect(() => {
    const urlSearch = new URLSearchParams(window.location.search);

    const platformId = urlSearch.get('platformId');
    if (platformId) {
      platformList.map(platform => {
        if (platform.id === platformId) {
          setPlatformId(platform.id);
          localStorage.setItem('platformId', platformId);
          platform.returnUrl && setReturnUrl(platform.returnUrl);
          platform.cancelUrl && setCancelUrl(platform.cancelUrl);
          platform.policyUrl && setPolicyUrl(platform.policyUrl);
        }
      });

      setSearchPlatformId(platformId);
      setDisabledPlatformId(true);
    }
  }, [platformList]);

  return (
    <ThemeProvider theme={defaultTheme}>
      {/* <AppAppBar mode={mode} toggleColorMode={toggleColorMode} /> */}

      <Button
        color='primary'
        variant='contained'
        size='small'
        component='a'
        rel='noopener'
        onClick={() => window.location.replace(cancelUrl)}
      >
        <KeyboardReturnIcon />
      </Button>
      <Container component='main' maxWidth='xs'>
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Avatar sx={{m: 1, bgcolor: 'secondary.main'}}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component='h1' variant='h5'>
            {t('signin')}
          </Typography>
          <FormControl sx={{m: 1, minWidth: 120}} disabled></FormControl>
          <Box component='form' onSubmit={handleSubmit} sx={{mt: 1}}>
            <TextField
              margin='normal'
              required
              fullWidth
              id='email'
              label={t('signinEmail')}
              name='email'
              autoComplete='email'
              autoFocus
              value={email}
              onChange={({target: {value}}) => {
                setEmail(value);
              }}
            />
            <TextField
              margin='normal'
              required
              fullWidth
              name='password'
              label={t('signinPassword')}
              type='password'
              id='password'
              autoComplete='current-password'
              value={password}
              onChange={({target: {value}}) => {
                setPassword(value);
              }}
            />
            <FormControl required fullWidth disabled={disabledPlatformId}>
              <InputLabel id='platform-simple-select-label'>
                {t('signinPlatformName')}
              </InputLabel>
              <Select
                labelId='platform-simple-select-label'
                id='platform-simple-select'
                value={searchPlatformId || platformId}
                label={t('signinPlatformName')}
                onChange={v => {
                  setPlatformId(v.target.value);
                }}
              >
                {platformList
                  .filter(p => {
                    if (disabledPlatformId) {
                      return p;
                    }
                    return !!p.isActive;
                  })
                  .map(g => {
                    return <MenuItem value={g.id}>{g.name}</MenuItem>;
                  })}
              </Select>
            </FormControl>
            <FormControlLabel
              control={<Checkbox value='remember' color='primary' />}
              label={t('rememberMe')}
            />
            <Button
              type='submit'
              fullWidth
              variant='contained'
              sx={{mt: 3, mb: 2}}
              endIcon={<LoginIcon />}
            >
              {t('signin')}
            </Button>
            <GoogleLoginButton
              t={t}
              platformId={platformId}
              successFunc={successFunc}
              handleClick={handleClick}
            />
            <Grid sx={{mt: 2, mb: 2}}></Grid>
            <Grid container>
              {/* <Grid item xs>
                <Link href='#' variant='body2'>
                  Forgot password?
                </Link>
              </Grid> */}
              <Grid item>
                <Link href={`sign-up${window.location.search}`} variant='body2'>
                  {t('signinNoAccountSignup')}
                </Link>
              </Grid>
            </Grid>
          </Box>
        </Box>
        <Copyright sx={{mt: 8, mb: 4}} />
      </Container>
      <Snackbar
        anchorOrigin={{vertical: state.vertical, horizontal: state.horizontal}}
        open={open}
        autoHideDuration={ALERT_HIDE_DURATION}
        onClose={handleClose}
      >
        <Alert
          onClose={handleClose}
          severity={state.type}
          variant='filled'
          sx={{width: '100%'}}
        >
          {state.message}
        </Alert>
      </Snackbar>
    </ThemeProvider>
  );
}
