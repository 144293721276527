import React from 'react';
import {GoogleOAuthProvider, useGoogleLogin} from '@react-oauth/google';
import {AlertColor, Button, Icon} from '@mui/material';
import GoogleIcon from '@mui/icons-material/Google';
import {TFunction} from 'i18next';
import {postGoogleSignIn} from '../api/auth';
import {HttpStatusCode} from 'axios';

const GoogleOauthButton = ({
  buttonText = '',
  platformId = '',
  t = (tStr: string) => '',
  onSuccess = (res: any) => {},
  handleClick = (type: AlertColor, message: string) => {},
  onError = () => {},
}) => {
  const googleOAuth = useGoogleLogin({
    flow: 'auth-code',
    onSuccess,
    onError,
  });

  const handleCheckOauth = () => {
    if (!platformId) {
      handleClick('error', t('signinRequiredPlatformId'));
      return;
    }
    return googleOAuth();
  };

  return (
    <Button variant='outlined' onClick={handleCheckOauth}>
      <GoogleIcon />
      {buttonText}
    </Button>
  );
};

export const GoogleLoginButton = ({
  t,
  platformId,
  successFunc,
  handleClick,
}: {
  t: TFunction<'translation', undefined>;
  platformId: string;
  successFunc: (response: any) => void;
  handleClick: (type: AlertColor, message: string) => void;
}) => {
  const successResponse = async (response: any) => {
    const result = await postGoogleSignIn({...response, platformId});
    if (result?.status === HttpStatusCode.Ok) {
      successFunc(result);
    } else {
      handleClick('error', result?.data.message);
    }
  };

  return (
    <>
      <GoogleOAuthProvider
        clientId={`${process.env.REACT_APP_GOOGLE_CLIENT_ID}`}
      >
        <GoogleOauthButton
          onSuccess={res => {
            successResponse(res);
          }}
          buttonText={t('signinGoogleSign')}
          platformId={platformId}
          handleClick={handleClick}
          t={t}
        ></GoogleOauthButton>
      </GoogleOAuthProvider>
    </>
  );
};
