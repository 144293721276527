import React from 'react';
import {Box, Typography} from '@mui/material';
import AppAppBar from '../components/AppAppBar';
import {Trans, useTranslation} from 'react-i18next';

interface Policy {
  id: number;
  title: string;
  content: string;
}

const ProductPolicy: React.FC = () => {
  const {t} = useTranslation();
  return (
    <>
      <AppAppBar mode={'light'} toggleColorMode={() => {}}></AppAppBar>
      <Box
        p={3}
        marginTop={10}
        display='flex'
        justifyContent='center'
        alignItems='center'
        minHeight='100vh'
      >
        <Typography variant='body1'>
          <Typography variant='h4' gutterBottom>
            {t('productPolicyTitle')}
          </Typography>
          {/* {t('productPolicyDescription')} */}
          <Trans
            i18nKey='productPolicyDescription'
            components={{ul: <ul />, li: <li />}}
          />
        </Typography>
      </Box>
    </>
  );
};

export default ProductPolicy;
