import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {useTranslation} from 'react-i18next';

export default function FAQ() {
  const {t} = useTranslation();
  const [expanded, setExpanded] = React.useState<string | false>(false);

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };

  return (
    <Container
      id='faq'
      sx={{
        pt: {xs: 4, sm: 12},
        pb: {xs: 8, sm: 16},
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: {xs: 3, sm: 6},
      }}
    >
      <Typography
        component='h2'
        variant='h4'
        color='text.primary'
        sx={{
          width: {sm: '100%', md: '60%'},
          textAlign: {sm: 'left', md: 'center'},
        }}
      >
        {t('faq')}
      </Typography>
      <Box sx={{width: '100%'}}>
        <Accordion
          expanded={expanded === 'panel1'}
          onChange={handleChange('panel1')}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls='panel1d-content'
            id='panel1d-header'
          >
            <Typography component='h3' variant='subtitle2'>
              {t('faq1Title')}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography
              variant='body2'
              gutterBottom
              sx={{maxWidth: {sm: '100%', md: '70%'}}}
            >
              {t('faq1Content')}
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={expanded === 'panel2'}
          onChange={handleChange('panel2')}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls='panel2d-content'
            id='panel2d-header'
          >
            <Typography component='h3' variant='subtitle2'>
              {t('faq2Title')}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography
              variant='body2'
              gutterBottom
              sx={{maxWidth: {sm: '100%', md: '70%'}}}
            >
              {t('faq2Content')}
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={expanded === 'panel3'}
          onChange={handleChange('panel3')}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls='panel3d-content'
            id='panel3d-header'
          >
            <Typography component='h3' variant='subtitle2'>
              {t('faq3Title')}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography
              variant='body2'
              gutterBottom
              sx={{maxWidth: {sm: '100%', md: '70%'}}}
            >
              {t('faq3Content')}
            </Typography>
          </AccordionDetails>
        </Accordion>
      </Box>
    </Container>
  );
}
