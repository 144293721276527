import {
  Alert,
  AlertColor,
  ButtonBase,
  Snackbar,
  SnackbarOrigin,
  Typography,
  styled,
} from '@mui/material';
import Box from '@mui/material/Box';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {getPlatformList} from '../../api/platform';
import {ALERT_HIDE_DURATION} from '../../constant';
import {useNavigate} from 'react-router-dom';

const ImageButton = styled(ButtonBase)(({theme}) => ({
  position: 'relative',
  height: 200,
  [theme.breakpoints.down('sm')]: {
    width: '100% !important', // Overrides inline-style
    height: 100,
  },
  '&:hover, &.Mui-focusVisible': {
    zIndex: 1,
    '& .MuiImageBackdrop-root': {
      opacity: 0.15,
    },
    '& .MuiImageMarked-root': {
      opacity: 0,
    },
    '& .MuiTypography-root': {
      border: '4px solid currentColor',
    },
  },
}));

const ImageSrc = styled('span')({
  position: 'absolute',
  left: 0,
  right: 0,
  top: 0,
  bottom: 0,
  backgroundSize: 'cover',
  backgroundPosition: 'center 40%',
});

const Image = styled('span')(({theme}) => ({
  position: 'absolute',
  left: 0,
  right: 0,
  top: 0,
  bottom: 0,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  color: theme.palette.common.white,
}));

const ImageBackdrop = styled('span')(({theme}) => ({
  position: 'absolute',
  left: 0,
  right: 0,
  top: 0,
  bottom: 0,
  backgroundColor: theme.palette.common.black,
  opacity: 0.4,
  transition: theme.transitions.create('opacity'),
}));

const ImageMarked = styled('span')(({theme}) => ({
  height: 3,
  width: 18,
  backgroundColor: theme.palette.common.white,
  position: 'absolute',
  bottom: -2,
  left: 'calc(50% - 9px)',
  transition: theme.transitions.create('opacity'),
}));

interface State extends SnackbarOrigin {
  open: boolean;
  type: AlertColor;
  message: string;
}
function DashboardContent() {
  const {t, i18n} = useTranslation();
  const navigate = useNavigate();
  const localeI18n = localStorage.getItem('i18n');
  const [platformList, setPlatformList] = React.useState<
    {
      id: string;
      name: string;
      isActive: boolean;
      img?: string;
    }[]
  >([]);
  const [state, setState] = React.useState<State>({
    open: false,
    type: 'success',
    message: '',
    vertical: 'bottom',
    horizontal: 'right',
  });
  const {open} = state;
  const handleClick = (type: AlertColor, message: string) => {
    setState({...state, type, message, open: true});
  };

  const handleClose = () => {
    setState({...state, open: false});
  };

  const handlePressPlatform = (platformId: string) => {
    localStorage.setItem('platformId', platformId);
    navigate('/dashboard');
  };

  React.useEffect(() => {
    i18n.changeLanguage(localeI18n as string);
  }, []);

  React.useEffect(() => {
    getPlatformList({limit: 1000})
      ?.then(d => {
        setPlatformList(d.data.items);
      })
      .catch(e => {
        handleClick('error', e);
      });
  }, []);

  return (
    <>
      <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          marginBottom: 8,
        }}
      >
        <Typography component='h1' variant='h5'>
          {t('dashboardChoosePlatform')}
        </Typography>
      </Box>
      <Box
        sx={{display: 'flex', flexWrap: 'wrap', minWidth: 300, width: '100%'}}
      >
        {platformList.map(platform => (
          <ImageButton
            focusRipple
            key={platform.name}
            style={{
              width: '60%',
              marginBottom: '1rem',
              margin: '0 auto',
            }}
            onClick={() => handlePressPlatform(platform.id)}
          >
            <ImageSrc
              style={{
                backgroundImage: `url(${
                  platform.img || '%PUBLIC_URL%/platform.jpg'
                })`,
              }}
            />
            <ImageBackdrop className='MuiImageBackdrop-root' />
            <Image>
              <Typography
                component='span'
                variant='subtitle1'
                color='inherit'
                sx={{
                  position: 'relative',
                  p: 4,
                  pt: 2,
                  pb: theme => `calc(${theme.spacing(1)} + 6px)`,
                }}
              >
                {platform.name}
                <ImageMarked className='MuiImageMarked-root' />
              </Typography>
            </Image>
          </ImageButton>
        ))}
        <Snackbar
          anchorOrigin={{
            vertical: state.vertical,
            horizontal: state.horizontal,
          }}
          open={open}
          autoHideDuration={ALERT_HIDE_DURATION}
          onClose={handleClose}
        >
          <Alert
            onClose={handleClose}
            severity={state.type}
            variant='filled'
            sx={{width: '100%'}}
          >
            {state.message}
          </Alert>
        </Snackbar>
      </Box>
    </>
  );
}

export default function ChoosePlatform() {
  return <DashboardContent></DashboardContent>;
}
